<template>
    <div class="insurance ">
        <div class="header">
            <a-icon class="back" @click="$router.go(-1)" type="left" />
            <span class="title">BCI Insurance</span>
        </div>
        <div v-if="step==0" class="box step2">
            <div class="content" @scroll.passive="getScroll($event)" v-html="article.content"></div>
            <div v-if="!readed || timeleft>0" class="timer">
                {{$t('insurance.step2.pleaseRead')}} <span v-if="timeleft>0">{{timeleft}}S</span>
            </div>
            <div v-if="timeleft<=0 && readed && !confirmAddr" >
                <div class="confirm" @click="confirmAddr=true">
                    <span class="address">{{walletAddress}}</span>
                    <div>{{$t('insurance.step2.confirmAddress')}}</div>
                </div>
            </div>
            <div v-if="confirmAddr && readed" class="continue">
                <div class="btn-primary" @click="approve()">
                    {{$t('insurance.step2.agreeBtn')}}
                </div>
                <span>{{$t('insurance.step2.signTips')}}</span>
            </div>
        </div>
        <div v-if="step==1" class="box step3">
            <a-form-item>
                <span class="label" slot="label">{{$t('insurance.step3.labels')[0]}}</span>
                <a-input class="bg-sec" size="large" :value="walletAddress" disabled />
            </a-form-item>
            <a-form-item>
                <div class="label flex" slot="label">
                    <span>{{$t('insurance.step3.labels')[1]}}</span>
                </div>
                <div class="amount">
                    {{ insuranceAmount }}USDT
                </div>
            </a-form-item>
            <div class="periods">
                <div class="label flex">
                    <span>{{$t('insurance.step3.labels')[2]}}</span>
                </div>
                <div class="btns">
                    <span class="btn" :class="period==0?'on':''" @click="period=0">{{ $t('insurance.types')[0] }}</span>
                    <span class="btn" :class="period==1?'on':''" @click="period=1">{{ $t('insurance.types')[1] }}</span>
                </div>
            </div>
            <div class="btn-primary mg30" @click="insurance">
                {{$t('common.submit')}}
            </div>
            <div class="btn-primary-ghost" @click="step=step-1">
                {{$t('insurance.prev')}}
            </div>
        </div>
        <div class="color-gray serviceBy">
            {{$t('insurance.serviceBy')}}
        </div>
    </div>
</template>
<script>
export default {
    name: "Insurance",
    data() {
        return {
            userinfo: {
                address: '',balance:0.00, node_level:0,id:0,total_flow:0,usdt_rate:0.5,insurance_total:0,insurance_profit_total:0,insurance_dividend_total:0,
            },
            article:{},
            step: 0,
            timeleft: 10,
            insuranceAmount: 1000,
            insurancedAmount: 0,
            totalDividend: 0,
            approvedAmount: 0,
            userUsdtBalance: 0,
            period: 0,
            confirmAddr: false,
            itvl: {},
            readed:false,
            walletAddress: '',
            data: [],
            loading: true,
            paused: false,
        };
    },
    components: { 
    },
    mounted() {
        let _this = this;
        let _obj = setInterval(()=>{
        if (window.walletAddress && window.insuranceContract && window.usdtToken) {
            clearInterval(_obj)
            _this.getApprovedAmount();
            _this.walletAddress = window.walletAddress;
            window.usdtToken.balanceOf(window.walletAddress).call().then((res)=>{
                console.log('usdt'+res.toNumber()/1000000);
                _this.userUsdtBalance = res.toNumber();
            })
        }
        }, 200);
        this.getInsuranceArc();
    },
    methods: {
        // insuranceAmountOnChange(val){
        //     this.insuranceAmount = val;
        // },
        getScroll(event) {
            // 滚动条距离底部的距离scrollBottom
            let scrollBottom =
                event.target.scrollHeight -
                event.target.scrollTop -
                event.target.clientHeight;
                // console.log(scrollBottom) // 滚动到底部的距离
            if ( scrollBottom <= 10) { // 判断滚动到底部时
                this.readed = true;
            }
        },
        getInsuranceArc(){
            let _this =this;
            this.$http.call(this.$http.api.policy,{
                data:{id: 5}
            }).then(res=>{
                console.log(res);
                this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.article = resp.data;
                    _this.itvl = setInterval(()=>{
                        _this.timeleft -= 1;
                        if(_this.timeleft == 0){
                            clearInterval(_this.itvl);
                        }
                    }, 1000);
                }else{
                    this.$message.error(resp.data);
                } 
                
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                
            });
        },
        getUserinfo() {
            let _this = this
            this.$http.call(this.$http.api.userinfo).then(res=>{
                console.log(res);
                _this.userinfo = res.data.data;
                _this.$store.commit('setUserinfo', res.data.data);

            },res=>{
                // console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            }).then(()=>{
                
            });
        },
        getApprovedAmount(){
            let _this = this;
            window.usdtToken.allowance(window.walletAddress, window._INSURANCE_CONTRACT).call().then((res)=>{
              let _approvedAmount=0;
              if(res.remaining===undefined){
                _approvedAmount = res.toNumber();
              }else{
                _approvedAmount = res.remaining.toNumber();
              }
              _this.approvedAmount = _approvedAmount;
            });
        },
        approve(){
            let _this = this;
            window.usdtToken.increaseApproval(window._INSURANCE_CONTRACT, _this.insuranceAmount*1000000).send().then(()=>{
                _this.step += 1;
            });
        },
        async insurance(){
            if(this.paused){
                this.$message.info(this.$t('common.commingsoon'));
                return;
            }
            let _this = this;
            let usdtAmount = this.insuranceAmount*1000000;
            if(this.userUsdtBalance < usdtAmount){
                this.$message.info(this.$t('common.lackOfBalance'));
                return;
            }
            console.log(_this.approvedAmount, usdtAmount);
            try{
                if(this.approvedAmount < usdtAmount){
                    //授權
                    window.usdtToken.increaseApproval(window._INSURANCE_CONTRACT, usdtAmount).send().then(()=>{
                        setTimeout(()=>{
                            _this.getApprovedAmount();
                        },1500);
                        if(window.insuranceContract){
                            window.insuranceContract.buyInsurance(usdtAmount, _this.period).send().then(()=>{
                                _this.$message.success(_this.$t('common.submitSuccess'));
                                setTimeout(()=>{
                                    _this.$router.push({name:'insurances'});
                                },1500);
                            });
                        }
                    });
                }else{
                    if(window.insuranceContract){
                        window.insuranceContract.buyInsurance(usdtAmount, _this.period).send().then(()=>{
                            _this.$message.success(_this.$t('common.submitSuccess'));
                            setTimeout(()=>{
                                _this.$router.push({name:'insurances'});
                            },1500);
                        });
                    }
                }
            }catch(e){
                console.error(e)
            }
        },
    },
};
</script>
<style lang="less" scoped>
.insurance {
    width:100%;
    padding: 70px 15px;
    text-align: center;
    .box{
        padding: 15px;
        // margin-bottom: 15px;
        .title{
            font-size: 20px;
            font-weight: bold;
            color: @primary-color;
            position:relative;
            display: flex;
            justify-content: center;
            align-items: center;
            .back{
                position: absolute;
                left:0;
            }
        }
        .tips{
            text-align: left;
            color: @gray;
            margin: 15px 0;
        }.center{
            text-align: center;
        }
    }.step2{
        padding-bottom: 30px;
        .title{
            padding-bottom: 15px;
        }
        .content{
            height: 580px;
            overflow-y: auto;
        }
        .confirm{
            padding: 15px;
            border: 1px solid @primary-color;
            .address{
                margin-bottom: 15px;
            }
        }
        .timer{
            background: rgba(88,88,88,0.5);
            color: @primary-color;
            font-weight: bold;
            padding: 15px;
            margin: 15px auto;
        }
    }.step3{
        .title{
            padding-bottom: 20px;
        }
        .label {
            color: @white;
        }
        .amount {
            text-align: left;
            color: #fff;
        }
        .btns{
            text-align: left;
            padding-top: 15px;
            .btn{
                border: 0;
                padding: 5px 10px;
                background: @white;
                color: @black;
                &:first-child{
                    border-radius: 6px 0 0 6px;
                }
                &:last-child{
                    border-radius: 0 6px 6px 0;
                }
            }.on{
                background: @primary-color; 
            }
            
        }
    }
    
}
</style>
